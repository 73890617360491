<template>
  <v-autocomplete
    label="Refund Policy"
    :items="policies"
    :loading="loading"
    :disabled="disabled"
    v-model="selected"
    item-text="name"
    item-value="id"
    item-color="color3"
    color="color3"
    clearable
  >
    <template v-slot:no-data>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            You do not have any custom refund policies.
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
    <template v-slot:append-outer>
      <v-btn
        v-if="warning"
        color="warning"
        fab x-small
        @click.stop="$emit('warning-click', 'Refund Policy')"
      >
        <v-icon>fas fa-exclamation</v-icon>
      </v-btn>
    </template>

  </v-autocomplete>
</template>

<script>
export default {
  props: ['value', 'username', 'useDefault', 'disabled', 'warning'],
  data () {
    return {
      policies: [],
      loading: false,
      selected: this.value
    }
  },
  methods: {
    getPolicies () {
      this.loading = true
      this.$VBL.cart.refundPolicy.getAll(this.username, true)
        .then(r => {
          this.policies = r.data
          if (this.useDefault) this.selectDefault()
        })
        .catch(e => console.log(e))
        .finally(() => { this.loading = false })
    },
    selectDefault () {
      const d = this.policies.find(f => f.default)
      if (d) this.selected = d.id
    },
    handleInput (e) {
      this.$emit('input', this.selected)
    }
  },
  watch: {
    selected: 'handleInput'
  },
  mounted () {
    this.getPolicies()
  }
}
</script>
